const GuidedLearningObjective = require('./GuidedLearningObjective');
const GuidedLearningObjectiveType = require('./GuidedLearningObjectiveType');

const TYPE_FIELD_NAME = 'type';
const NAME_FIELD_NAME = 'name';

const QUESTION_COUNT_FIELD_NAME = 'questionCount';
const START_FIELD_NAME = 'started';
const CERT_DONE_FIELD_NAME = 'certDone';
const VIDEO_COUNT_FIELD_NAME = 'videoCount';
const MODULE_COUNT_FIELD_NAME = 'moduleCount';
const IS_OVERDUE = 'overdue';
const DAYS_UNTIL_DUE = 'daysUntilDue';

class CertificationLearningObjective extends GuidedLearningObjective {
  defaults() {
    return {
      name: '',
      pointsAvailable: 0,
      [TYPE_FIELD_NAME]: GuidedLearningObjectiveType.CERTIFICATION_OBJECTIVE
    };
  }

  getName() {
    return this.get(NAME_FIELD_NAME);
  }

  canBegin() {
    return this.getActionableItem() != null && this.getEnrollmentStatus();
  }

  getNumberOfQuestions() {
    const questionCount = this.getObjectiveDetails()[QUESTION_COUNT_FIELD_NAME];
    return questionCount || 0;
  }

  getNumberOfVideos() {
    const videoCount = this.getObjectiveDetails()[VIDEO_COUNT_FIELD_NAME];
    return videoCount || 0;
  }

  getNumberOfModules() {
    const moduleCount = this.getObjectiveDetails()[MODULE_COUNT_FIELD_NAME];
    return moduleCount || 0;
  }

  getIsOverdue() {
    return this.getObjectiveDetails()[IS_OVERDUE];
  }

  getDaysUntilDue() {
    return this.getObjectiveDetails()[DAYS_UNTIL_DUE];
  }

  isComplete() {
    const details = this.getObjectiveDetails();
    return details[CERT_DONE_FIELD_NAME];
  }

  hasStarted() {
    const details = this.getObjectiveDetails();
    return details[START_FIELD_NAME];
  }
}

module.exports = CertificationLearningObjective;
