// Module
var code = `<form class="js-community-type-toggle community-type-toggle">

  <% if (isDistributedAdminEnabled) { %>

    <label for="business-unit"><%- t('general.businessUnit') %></label>
    <%= axSelect({ id: 'business-unit' , data: { field: 'businessUnitId' , options: 'businessUnits' , shallow: 'true' },
      disabled: disabled, value: selectedBusinessUnit, noFormEditorDefault: 'true' , 'aria-label' :
      t('general.businessUnit'), 'aria-describedby' : 'business-unit-helper' }) %>
      <% if (!isChannel) { %>
        <div id="business-unit-helper" class="ax-form-helper-text ax-font--secondary ax-font__caption"
          style="margin-top: var(--ax-spacing-xs)"><%- t('communitiesManagement.accessSettings.businessUnit.helpText')
            %>
        </div>
        <% } %>
          <% } %>
            <% if (isCommsEnabled) { %>

              <p style="margin-top: var(--ax-spacing-xl)"><%- t(accessTitle) %>
              </p>
              <div class="ax-grid ax-grid__col--12">
                <ul id="community-types" class="ax-grid__col--3" data-field="communityAccessType"
                  data-editor="RadioGroup" data-options="communityAccessTypes">
                </ul>
                <div class="ax-grid__col--6">
                  <p id="group-type-extra-info" class="ax-font--secondary" aria-hidden="true">
                    <%- t('communitiesManagement.community.groupTypeGrantAccess') %>
                  </p>
                  <p id="team-type-extra-info" class="ax-font--secondary" aria-hidden="true">
                    <%- t('communitiesManagement.community.teamTypeGrantAccess') %>
                  </p>
                </div>
              </div>
              <hr aria-hidden="true">
              <% } %>
                <h3><%- t('communitiesManagement.accessSettings.businessUnit.targetAudience') %></h3>
                <div class="js-table-region"></div>
</form>
`;
// Exports
module.exports = code;