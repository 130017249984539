const { Collection } = require('Backbone');
const I18n = require('@common/libs/I18n');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const Form = require('@common/components/forms/Form');
const GroupAccessLayout = require('@training/apps/search/communitiesManagement/accessSettings/GroupAccessLayout');
const TeamAccessLayout = require('@training/apps/search/communitiesManagement/accessSettings/TeamAccessLayout');
const CommunityAccessType = require('@training/apps/training/enums/CommunityAccessType');
const Marionette = require('Marionette');
const BusinessUnitRepository = require('@common/data/collections/businessUnit/BusinessUnitRepository');
const BusinessUnitList = require('@common/data/collections/businessUnit/BusinessUnitList');
const BusinessUnitType = require('@common/data/enums/BusinessUnitType');

class AccessSettingsTabView extends Marionette.LayoutView {
  getTemplate() {
    return require('@training/apps/search/communitiesManagement/accessSettings/AccessSettingsTabView.html');
  }

  className() {
    return 'access-settings-form';
  }

  isGlobalBu(buId) {
    return BusinessUnitType.isGlobal(buId);
  }

  ui() {
    return {
      communityAccessTypeToggle: '.js-community-type-toggle',
      businessUnitSelect: '.js-business-unit-select'
    };
  }

  events() {
    return {
      'change @ui.businessUnitSelect': '_onBusinessUnitChange'
    };
  }

  regions() {
    return {
      tableRegion: '.js-table-region'
    };
  }

  templateHelpers() {
    const businessUnitId = this.model.get('businessUnitId');
    const hasOnlyOneBu = this.businessUnitList?.length === 1;
    const hasMultipleBus = this.businessUnitList?.length > 1;
    const isGlobalChannelBU = this.isGlobalBu(businessUnitId) && this.isChannel;

    let selectedBusinessUnit = businessUnitId;
    if (this.isGlobalBu(businessUnitId)) {
      selectedBusinessUnit = BusinessUnitType.GLOBAL;
    } else if (businessUnitId === BusinessUnitType.UNSELECTED) {
      selectedBusinessUnit = BusinessUnitType.UNSELECTED;
    }


    if (this.isNewCommunity && hasMultipleBus && selectedBusinessUnit !== BusinessUnitType.UNSELECTED) {
      selectedBusinessUnit = BusinessUnitType.UNSELECTED;
    }

    return {
      isCommsEnabled: this.isCommsEnabled,
      isNewCommunity: this.isNewCommunity,
      accessTitle: this.model.get('type') === 'channel' ? 'channels.settings.channelAccess' : 'communitiesManagement.community.communityTypeQuestion',
      selectedBusinessUnit: selectedBusinessUnit,
      disabled: (!this.isNewCommunity || hasOnlyOneBu) && !isGlobalChannelBU,
      isDistributedAdminEnabled: TenantPropertyProvider.get().getProperty('teamAdminEnabled'),
      hasMultipleBus: hasMultipleBus,
      isChannel: this.isChannel
    };
  }

  initialize(options = {}) {
    this.groupList = options.groupList;
    this.teamsList = options.teamsList;
    this.isCommsEnabled = TenantPropertyProvider.get().getProperty('communicationsEnabled');
    this.businessUnitList = new BusinessUnitList();
    this.isNewCommunity = this.model.isNew();
    this.hasOnlyOneBusinessUnit = false;
    this.isChannel = this.model.get('type') === 'channel';
    this.showBanner = false;

    // Initialize the community management state system
    this._initializeStateSystem();

    const businessUnitId = this.model.get('businessUnitId');
    if (this.isGlobalBu(businessUnitId)) {
      this.model.set('businessUnitId', BusinessUnitType.GLOBAL, { silent: true });
    } else {
      this._onBusinessUnitChange(businessUnitId, true);
    }
    this.listenTo(this.model, 'change:businessUnitId', this._onBusinessUnitIdChange);
  }

  _initializeStateSystem() {
    if (window.app && window.app.layout) {
      window.app.layout.communityManagementState = window.app.layout.communityManagementState || {};
    }
  }

  _getCachedBusinessUnitId() {
    return window.app && window.app.layout && window.app.layout.communityManagementState
      && window.app.layout.communityManagementState.selectedBusinessUnitId;
  }

  _setCachedBusinessUnitId(buId) {
    if (window.app && window.app.layout) {
      if (!window.app.layout.communityManagementState) {
        window.app.layout.communityManagementState = {};
      }
      window.app.layout.communityManagementState.selectedBusinessUnitId = buId;
    }
  }

  _onBusinessUnitIdChange(model, value) {
    if (this.ui.businessUnitSelect && this.ui.businessUnitSelect.length) {
      this.ui.businessUnitSelect.val(this.isGlobalBu(value) ? BusinessUnitType.GLOBAL : value);
    }
  }

  onShow() {
    const businessUnitId = this.model.get('businessUnitId');
    const isExistingCommunity = !this.isNewCommunity;

    // Check if there's a cached Business Unit selection
    const cachedBusinessUnitId = this._getCachedBusinessUnitId();

    // If we have a cached selection and we're switching tabs (not on initial load)
    if (cachedBusinessUnitId !== undefined && !this.isNewCommunity && businessUnitId !== cachedBusinessUnitId) {
      // Apply the cached selection to restore the user's choice
      this.model.set('businessUnitId', cachedBusinessUnitId, { silent: true });
    } else if (this.isGlobalBu(businessUnitId)) {
      if (!isExistingCommunity) {
        this.model.set('businessUnitId', BusinessUnitType.GLOBAL, { silent: true });
      }
    }

    if (!this.isCommsEnabled) {
      this._renderTable(this.model.get('communityAccessType'));
    }

    const teamAdminEnabled = TenantPropertyProvider.get().getProperty('teamAdminEnabled');
    const repo = new BusinessUnitRepository(
      window.apps.auth.session.user,
      teamAdminEnabled
    );

    repo.getAll({
      fetch: true
    }, (businessUnitList) => {
      this.businessUnitList.reset(businessUnitList.models);

      if (!this.isDestroyed) {
        this._setupCommunityTypeForm(businessUnitList);
        if (this.isNewCommunity) {
          const fetchPromises = [];

          if (this.groupList && this.groupList.length === 0) {
            fetchPromises.push(new Promise((resolve) => {
              this.groupList.fetch({
                reset: true,
                success: function() {
                  return resolve()
                },
                error: function() {
                  return resolve()
                }
              });
            }));
          }

          if (this.teamsList && this.teamsList.length === 0) {
            fetchPromises.push(new Promise((resolve) => {
              this.teamsList.fetch({
                reset: true,
                success: function() {
                  return resolve()
                },
                error: function() {
                  return resolve()
                }
              });
            }));
          }

          Promise.all(fetchPromises).then(() => {
            if (!this.isDestroyed) {
              this._renderTable(this.model.get('communityAccessType'));
            }
          });
        }
      }
    });
  }

  _setupCommunityTypeForm(businessUnitsCollection) {
    const isBuAdmin = window.apps.auth.session.user.isBuAdminUser();

    // Check if there's a cached Business Unit selection from tab switching
    const cachedBusinessUnitId = this._getCachedBusinessUnitId();

    if (!isBuAdmin) {
      const globalOption = businessUnitsCollection.find((model) => {
        const id = model.get('id');
        return this.isGlobalBu(id);
      });

      businessUnitsCollection.add({
        id: this.isNewCommunity && businessUnitsCollection.length > 1 ? null : '',
        name: {
          getValueForLanguage: function() {
            return I18n.t('settings.businessUnits.global')
          }
        }
      }, { at: 0 });

      businessUnitsCollection.remove(globalOption);
    }

    const existingBuId = this.model.get('businessUnitId');
    const isExistingCommunityWithBu = !this.isNewCommunity && !this.isGlobalBu(existingBuId);

    if (businessUnitsCollection.length === 1) {
      const singleBu = businessUnitsCollection.models[0];
      this.model.set('businessUnitId', singleBu.get('id'));
      this.businessUnitList.reset(businessUnitsCollection.models);
      this.render();
    } else if (businessUnitsCollection.length > 1) {
      if (this.isNewCommunity) {
        businessUnitsCollection.add({
          id: BusinessUnitType.UNSELECTED,
          name: {
            getValueForLanguage: function() {
              return I18n.t('communitiesManagement.dropdown.selectOne') || '--Select One--';
            }
          }
        }, { at: 0 });

        this.model.set('businessUnitId', BusinessUnitType.UNSELECTED);
      } else if (!isExistingCommunityWithBu) {
        businessUnitsCollection.add({
          id: '',
          name: {
            getValueForLanguage: function() {
              return I18n.t('settings.businessUnits.global');
            }
          }
        }, { at: 0 });

        this.model.set('businessUnitId', '');
      } else {
        const buExists = businessUnitsCollection.some((bu) => {
          return bu.get('id') === existingBuId;
        });

        if (!buExists) {
          businessUnitsCollection.add({
            id: BusinessUnitType.GLOBAL,
            name: {
              getValueForLanguage: function() {
                return I18n.t('settings.businessUnits.global');
              }
            }
          }, { at: 0 });

          this.model.set('businessUnitId', BusinessUnitType.GLOBAL);
        }
      }

      this.businessUnitList.reset(businessUnitsCollection.models);

      // Apply the cached BU ID if it exists and is different from the current setting
      if (cachedBusinessUnitId !== undefined && this.model.get('businessUnitId') !== cachedBusinessUnitId) {
        this.model.set('businessUnitId', cachedBusinessUnitId);

        // Ensure the UI is updated by triggering a render
        if (this.ui.businessUnitSelect && this.ui.businessUnitSelect.length) {
          this.ui.businessUnitSelect.val(cachedBusinessUnitId);
        }
      }
    }

    if (!this.model.get('communityAccessType')) {
      this.model.set('communityAccessType', CommunityAccessType.GROUP);
    }

    const communityAccessTypeCollection = new Collection([
      {
        id: CommunityAccessType.GROUP,
        ariaLabelledby: 'group-type-extra-info',
        label: I18n.t('communitiesManagement.community.groupType')
      },
      {
        id: CommunityAccessType.TEAM,
        ariaLabelledby: 'team-type-extra-info',
        label: I18n.t('communitiesManagement.community.teamType')
      }
    ]);

    // Create form with additional logging
    const communityAccessTypeForm = new Form({
      el: this.ui.communityAccessTypeToggle,
      model: this.model,
      context: {
        communityAccessTypes: communityAccessTypeCollection,
        businessUnits: businessUnitsCollection
      }
    });

    this.listenToOnce(communityAccessTypeForm, 'render', () => {
      if (!this.isDestroyed && this.ui.businessUnitSelect && this.ui.businessUnitSelect.length) {
        const currentBuId = this.model.get('businessUnitId');

        this.ui.businessUnitSelect.val(this.isGlobalBu(currentBuId) ? BusinessUnitType.GLOBAL : currentBuId);

        if (!this.isNewCommunity && !this.isGlobalBu(currentBuId)) {
          const buExists = Array.from(this.ui.businessUnitSelect.find('option')).some(
            (option) => {
              return option.value === currentBuId;
            }
          );

          if (buExists) {
            this.ui.businessUnitSelect.trigger('change');
          }
        }

        if (communityAccessTypeForm.validate) {
          communityAccessTypeForm.validate();
        }
      }
    });

    this.listenTo(
      communityAccessTypeForm,
      'change:communityAccessType',
      (form, editor) => {
        this._renderTable(
          editor.getValue(),
          this.model.get('businessUnitId')
        );
      }
    );

    this.listenTo(
      communityAccessTypeForm,
      'change:businessUnitId',
      (form, editor) => {
        this._onBusinessUnitChange(editor.getValue());
      }
    );

    this._renderTable(this.model.get('communityAccessType'));
  }

  _onBusinessUnitChange(buIdFromForm, isFirstLoad = false) {
    // if existing folder, BU change is not allowed;
    // existing channels only allow BU change if it's a global to specific BU
    const selectedBuId = buIdFromForm === undefined ? this.ui.businessUnitSelect.val() : buIdFromForm;
    if (!this.isNewCommunity && (!this.isChannel || isFirstLoad)) {
      const filteredTeamList = this._filterTeamsByBusinessUnit(selectedBuId);
      this.teamsList.reset(filteredTeamList);
      return;
    }

    // Persist the BU selection to the model so it's available across tabs
    this.model.set('businessUnitId', selectedBuId);

    // Store the selection in a session variable to ensure it persists during tab switching
    this._setCachedBusinessUnitId(selectedBuId);

    if (this.groupList) {
      this._resetGroupProperties();
      this._fetchGroups(selectedBuId);
    }

    if (this.teamsList) {
      this._resetTeamProperties();
      this._fetchTeams(selectedBuId);
    }
  }

  _resetGroupProperties() {
    this.groupList.reset();
    if (this.model.get('selectedGroups')) {
      this.model.get('selectedGroups').reset();

      if (!this.isNewCommunity && this.isChannel) {
        this.showBanner = true;
      }
    }
    this.model.set('groupAccess', []);
  }

  _resetTeamProperties() {
    const currentTeams = this.model.get('teams') || [];
    const currentOwningLocationId = this.model.get('owningLocationId');

    this.model.set({
      teams: [],
      teamAccess: [],
      owningLocationId: null,
      includeSubteams: false
    });

    this.teamsList.reset();

    if (!this.isNewCommunity && this.isChannel) {
      this.showBanner = true;
    }

    return {
      currentTeams,
      currentOwningLocationId
    };
  }

  _filterTeamsByBusinessUnit(selectedBuId) {
    if (this.isGlobalBu(selectedBuId) || BusinessUnitType.isUnselected(selectedBuId)) {
      return this.teamsList.models;
    }

    const matchingTeams = [];

    const searchTeamHierarchy = (team) => {

      // sometimes the team is not a Backbone model, but a plain object
      const teamObject = team.toJSON ? team.toJSON() : team;

      const teamBuId = teamObject.businessUnitId;
      const isMatch = String(teamBuId) === String(selectedBuId);

      if (isMatch) {
        // If we find a match, add it to our results
        matchingTeams.push(team);
      }

      // Always check children regardless of whether parent matched
      const children = teamObject.children;
      if (children && children.length) {
        children.forEach(searchTeamHierarchy);
      }
    };

    // Start the recursive search from each top-level team
    this.teamsList.forEach(searchTeamHierarchy);

    return matchingTeams;
  }

  _filterGroupsByBusinessUnit(selectedBuId) {
    if (BusinessUnitType.isUnselected(selectedBuId) || this.isGlobalBu(selectedBuId)) {
      return this.groupList.models;
    }

    if (this.isGlobalBu(selectedBuId)) {
      return this.groupList.filter((group) => {
        const groupBuId = group.get('businessUnitId');
        return this.isGlobalBu(groupBuId);
      });
    }
    return this.groupList.filter((group) => {
      const groupBuId = group.get('businessUnitId');
      return String(groupBuId) === String(selectedBuId);
    });
  }

  _fetchGroups(selectedBuId) {
    this._resetGroupProperties();

    const currentSelectedGroups = this.model.get('selectedGroups')
      ? this.model.get('selectedGroups').toJSON()
      : [];

    const fetchOptions = {
      reset: true,
      data: {}
    };

    if (!this.isGlobalBu(selectedBuId) && selectedBuId !== BusinessUnitType.GLOBAL && selectedBuId !== BusinessUnitType.UNSELECTED) {
      fetchOptions.data.businessUnitId = selectedBuId;
    }

    this.groupList.fetch({
      ...fetchOptions,
      success: () => {
        if (this.groupList.length > 0) {
          const filteredGroupList = this._filterGroupsByBusinessUnit(selectedBuId);
          this.groupList.reset(filteredGroupList);
        }

        if (currentSelectedGroups.length > 0 && !this.isNewCommunity) {
          const compatibleGroups = currentSelectedGroups.filter((group) => {
            if (BusinessUnitType.isUnselected(selectedBuId) || this.isGlobalBu(selectedBuId)) {
              return true;
            }
            return String(group.businessUnitId) === String(selectedBuId) || this.isGlobalBu(group.businessUnitId);
          });

          if (this.model.get('selectedGroups') && compatibleGroups.length > 0) {
            this.model.get('selectedGroups').reset(compatibleGroups);
          } else if (this.model.get('selectedGroups')) {
            this.model.get('selectedGroups').reset([]);
          }
        }

        const currentAccessType = this.model.get('communityAccessType');
        this._handleFetchResponse(currentAccessType, String(selectedBuId));
      },
      error: () => {
        const currentAccessType = this.model.get('communityAccessType');
        this._handleFetchResponse(currentAccessType, selectedBuId);
      }
    });
  }

  _fetchTeams(selectedBuId) {
    const result = this._resetTeamProperties();
    const currentTeams = result.currentTeams;
    const currentOwningLocationId = result.currentOwningLocationId;

    const fetchOptions = {
      reset: true,
      data: {}
    };

    if (!this.isGlobalBu(selectedBuId) && selectedBuId !== BusinessUnitType.GLOBAL && selectedBuId !== BusinessUnitType.UNSELECTED) {
      fetchOptions.data.businessUnitId = selectedBuId;
    }

    this.teamsList.fetch({
      ...fetchOptions,
      success: () => {
        if (this.teamsList.length > 0) {
          const filteredTeamList = this._filterTeamsByBusinessUnit(selectedBuId);
          this.teamsList.reset(filteredTeamList);
        }

        if (currentTeams.length > 0 && currentOwningLocationId && !this.isNewCommunity) {
          const compatibleOwningTeam = this.teamsList.get(currentOwningLocationId);
          if (compatibleOwningTeam) {
            this.model.set('owningLocationId', currentOwningLocationId);
          }
        }

        const currentAccessType = this.model.get('communityAccessType');
        this._handleFetchResponse(currentAccessType, selectedBuId);
      },
      error: () => {
        const currentAccessType = this.model.get('communityAccessType');
        this._handleFetchResponse(currentAccessType, selectedBuId);
      }
    });
  }

  _renderTable(communityAccessType, selectedBuId) {
    if (!this.isDestroyed) {
      if (communityAccessType === CommunityAccessType.TEAM) {
        this._renderTeamTable(selectedBuId);
      } else {
        this._renderGroupTable(selectedBuId);
      }
    }
  }

  _renderGroupTable(selectedBuId) {
    if (this.tableRegion && !this.isDestroyed) {
      const buId = selectedBuId || this.model.get('businessUnitId');
      const groupLayout = new GroupAccessLayout({
        model: this.model,
        groupList: this.groupList,
        businessUnitId: String(buId === BusinessUnitType.UNSELECTED ? BusinessUnitType.GLOBAL : buId),
        showBanner: this.showBanner
      });

      this.tableRegion.show(groupLayout);
    }
  }

  _renderTeamTable(selectedBuId) {
    if (this.tableRegion && !this.isDestroyed) {
      const buId = selectedBuId || this.model.get('businessUnitId');
      const teamLayout = new TeamAccessLayout({
        model: this.model,
        teamsList: this.teamsList,
        businessUnitId: String(buId === BusinessUnitType.UNSELECTED ? BusinessUnitType.GLOBAL : buId),
        showBanner: this.showBanner
      });

      this.tableRegion.show(teamLayout);
    }
  }

  _handleFetchResponse(currentAccessType, selectedBuId) {
    if (currentAccessType && !this.isDestroyed) {
      this.tableRegion.empty();
      this._renderTable(currentAccessType, selectedBuId);
    }
  }
};


module.exports = AccessSettingsTabView;

