const { ItemView } = require('Marionette');
const logging = require('logging');
const I18n = require('@common/libs/I18n');
const dateHelpers = require('@common/libs/dateHelpers');
const MetadataDetailItemView = require('@common/components/details/item/MetadataDetailItemView');
const TrainingModuleType = require('@common/data/enums/TrainingModuleType');

const URGENT_DAYS_REMAINING = 5;

const _getDaysRemaining = (dueDate) => {
  let useDate = dueDate;
  if (!(useDate instanceof Date)) {
    // First, see if it is a simple JSON object
    if (useDate.monthInYear) {
      useDate = new Date(useDate.year, useDate.monthInYear - 1, useDate.dayInMonth );

    // If not, see if the value can be parsed directly into a date
    } else {
      try {
        useDate = new Date(useDate);
      } catch (e) {
        logging.warn('Invalid date type used for dueDate in MetadataStringHelper._getDaysRemaining().');
        return null;
      }
    }
  }

  // Subtracting 1 day is necessary to make dueDate as exclusive rather than inclusive
  const endDate = new Date(useDate.getFullYear(), useDate.getMonth(), useDate.getDate() - 1);
  return dateHelpers.getDaysRemaining(apps.auth.session.serverTimeOffset, endDate);
};

const getLevelsViewDefinition = (levelCount) => {
  if (levelCount <= 0 || levelCount == null) {
    return null;
  }

  let value = '';
  if (levelCount === 1) {
    value = I18n.t('selfDirected.search.levels.singular');
  } else if (levelCount > 1) {
    value = I18n.t('selfDirected.search.levels.plural', { levelNum: levelCount });
  }

  return {
    id: 'levels',
    viewClass: MetadataDetailItemView,
    viewOptions: {
      value
    }
  };
};

const getDueDateViewDefinition = (dueDate, urgentDaysRemaining = URGENT_DAYS_REMAINING) => {
  if (dueDate == null) {
    return null;
  }

  const daysRemaining = _getDaysRemaining(dueDate);
  if (daysRemaining === null) {
    // This is the error condition return value - in which case, ignore the invalid due date
    return null;
  }

  const isOverdue = daysRemaining < 0;
  let className = 'metadata-detail-item text-muted';

  const viewDefinition = {
    id: 'dueDate',
    viewClass: MetadataDetailItemView,
    viewOptions: {
      className: isOverdue ? className += ' text-error' : className,
      bold: daysRemaining <= urgentDaysRemaining
    }
  };

  if (!isOverdue) {
    if (daysRemaining === 0) {
      Object.assign(viewDefinition.viewOptions, { value: I18n.t('assessments.item.dueBy.today') });
    } else if (daysRemaining === 1) {
      Object.assign(viewDefinition.viewOptions, { value: I18n.t('assessments.item.dueBy.singular') });
    } else {
      Object.assign(viewDefinition.viewOptions, { value: I18n.t('assessments.item.dueBy.plural', { daysLeft: daysRemaining }) });
    }
  } else {
    Object.assign(viewDefinition.viewOptions, {
      value: I18n.t('assessments.item.overDue')
    });
  }

  return viewDefinition;
};

const getDueDateString = (dueDate) => {
  if (dueDate == null) {
    return null;
  }

  const daysRemaining = _getDaysRemaining(dueDate);
  if (daysRemaining === null) {
    // This is the error condition return value - in which case, ignore the invalid due date
    return null;
  }

  const isOverdue = daysRemaining < 0;

  if (!isOverdue) {
    if (daysRemaining === 0) {
      return {
        string: I18n.t('assessments.item.dueBy.today'),
        isOverdue,
        daysRemaining
      };
    } else if (daysRemaining === 1) {
      return {
        string: I18n.t('assessments.item.dueBy.singular'),
        isOverdue,
        daysRemaining
      };
    }

    return {
      string: I18n.t('assessments.item.dueBy.plural', { daysLeft: daysRemaining }),
      isOverdue,
      daysRemaining
    };
  }

  return {
    string: I18n.t('assessments.item.overDue'),
    isOverdue
  };
};

const getModulesViewDefinition = (metaData) => {
  // This function can currently be called either with startableTopicLevel data (from a topic search)
  // or with session metaProperties (for the Today's Activities card on the hub) - but an argument is required for either
  if (metaData != null) {
    let modulesString;

    // This is the metaProperties option
    if (metaData.modules) {
      if (metaData.modules.value === 1) {
        modulesString = I18n.t('assessments.item.content.training.scorm');
      } else {
        modulesString = I18n.t(metaData.modules.strKey, { number: metaData.modules.value });
      }

    // This is the startableTopicLevel option
    } else {
      const moduleType = metaData.trainingModuleType;

      if (moduleType != null) {
        if (moduleType === TrainingModuleType.TRANSCODED_VIDEO || moduleType === TrainingModuleType.EXTERNAL_VIDEO) {
          modulesString = I18n.t('assessments.item.content.training.VideoFile');
        } else {
          modulesString = I18n.t('assessments.item.content.training.scorm');
        }
      }
    }

    // Make sure we were able to parse the module type and output an appropriate view definition
    if (modulesString != null) {
      return {
        id: 'modules',
        viewClass: MetadataDetailItemView,
        viewOptions: {
          value: modulesString
        }
      };
    }
  }

  // If we've gotten here, we were unable to get valid data to generate a view, so return null
  return null;
};

const getVideosViewDefinition = (metaData) => {
  if (metaData != null && metaData.videos != null) {
    let videosString;
    if (metaData.videos.value === 1) {
      videosString = I18n.t('assessments.item.content.training.VideoFile');
    } else {
      videosString = I18n.t(metaData.videos.strKey, { number: metaData.videos.value });
    }

    return {
      id: 'videos',
      viewClass: MetadataDetailItemView,
      viewOptions: {
        value: videosString
      }
    };
  }

  return null;
};

const getQuestionsViewDefinition = (metaData) => {
  if (metaData == null) {
    return null;
  }

  let questionString = '';
  const questionCount = metaData.questions || metaData.questionCount || parseInt(metaData, 10) || 0;

  if (questionCount > 0) {
    if (questionCount === 1) {
      questionString = I18n.t('assessments.item.content.questions.singular');
    } else if (questionCount > 1) {
      questionString = I18n.t('assessments.item.content.questions.plural', {count: questionCount});
    }

    return {
      id: 'questions',
      viewClass: MetadataDetailItemView,
      viewOptions: {
        value: questionString
      }
    };
  }

  return null;
};

const getNumberOfItemViewDefinition = (itemCount) => {
  let itemString = '';

  if (itemCount > 0) {
    if (itemCount === 1) {
      itemString = I18n.t('selfDirected.contents.itemCountSingular');
    } else if (itemCount > 1) {
      itemString = I18n.t('selfDirected.contents.itemCountPlural', { itemCount });
    }

    return {
      id: 'items',
      viewClass: MetadataDetailItemView,
      viewOptions: {
        value: itemString,
        iconClass: 'icon-list'
      }
    };
  }

  return null;
};

const getAttemptsRemainingViewDefinition = (attemptsRemaining) => {
  const attemptCount = parseInt(attemptsRemaining, 10) || 0;

  let attemptString;

  if (attemptCount > 0) {
    if (attemptCount === 1) {
      attemptString = I18n.t('assessments.item.attemptsRemaining.singular');
    } else if (attemptCount > 1) {
      attemptString = I18n.t('assessments.item.attemptsRemaining.plural', { count: attemptCount });
    }

    return {
      id: 'attemptsRemaining',
      viewClass: MetadataDetailItemView,
      viewOptions: {
        value: attemptString
      }
    };
  }

  return null;
};

/**
 * this creates an object that acts as a very simple View Definition to show a
 * string in MetadataDetailItemView for an author name.
 * @param {string} authorName
 */
const getLastEditorViewDefinition = (authorName) => {
  return {
    id: 'authorName',
    viewClass: MetadataDetailItemView,
    viewOptions: {
      value: authorName,
      valueClass: 'truncate-2',
      bold: true
    }
  };
};

/**
 * this creates an object that acts as a very simple View Definition to show a
 * string in MetadataDetailItemView for a last modified date.
 * @param {string} authorName
 */
const getLastModifiedDateViewDefinition = (lastModified) => {
  return {
    id: 'modifiedDate',
    viewClass: MetadataDetailItemView,
    viewOptions: {
      value: dateHelpers.timeFromEvent(lastModified),
      valueClass: 'truncate-2'
    }
  };
};

const getLastModifiedAndCommunityViewDefinition = (communityData = {}, lastModified) => {
  return {
    viewClass: ItemView,
    viewOptions: {
      className: 'community-date-info',
      template: `
        <% if (communityData.name !== '') { %>
          <a href="#">
            <%- communityData.name %>
          </a><span class="bullet" aria-hidden="true">
            &#8226;
          </span><p><%- lastModified %></p>
        <% } else { %>
          <p><%- lastModified %></p>
        <% } %>
      `,
      templateHelpers: {
        communityData,
        lastModified: dateHelpers.timeFromEvent(lastModified)
      },
      events: {
        'click a': (e) => {
          e.stopPropagation();
          communityData.onClick();
          return false;
        }
      }
    }
  };
};

module.exports = {
  getDueDateViewDefinition,
  getDueDateString,
  getLevelsViewDefinition,
  getModulesViewDefinition,
  getVideosViewDefinition,
  getQuestionsViewDefinition,
  getNumberOfItemViewDefinition,
  getAttemptsRemainingViewDefinition,
  getLastEditorViewDefinition,
  getLastModifiedDateViewDefinition,
  getLastModifiedAndCommunityViewDefinition
};
