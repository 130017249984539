import { getCssValue } from '@common/modules/react/themes/UiKit';

export default function uiKitBadge(cssVars: CSSStyleDeclaration) {
  return {
    badge: {
      size: getCssValue('--ax-badge-size', cssVars),
      borderRadius: getCssValue('--ax-badge-border-radius', cssVars),
      unread: getCssValue('--ax-badge-unread', cssVars)
    }
  };
}
