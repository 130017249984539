import { type QAClassName } from '@common/interfaces/SharedTypes';
import { joinClassNames } from '@common/libs/helpers/app/HTMLHelpers';
import { shouldForwardPropExcludeQaClassName } from '@common/libs/helpers/app/ReactHelpers';
import {
  Switch as MuiSwitch,
  styled,
  type SwitchProps as MuiSwitchProps
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';

type PropsToOmit = 'color'
  | 'disableFocusRipple'
  | 'disableRipple'
  | 'size'
  | 'action'
  | 'centerRipple'
  | 'disableTouchRipple'
  | 'focusRipple'
  | 'focusVisibleClassName'
  | 'LinkComponent'
  | 'TouchRippleProps'
  | 'touchRippleRef';

export type AxToggleProps = Omit<MuiSwitchProps, PropsToOmit> & {
  /**
   * If `true`, the radio won't use the branded color.
   * @default false
   * @optional
   */
  disabledBranding?: boolean
  qaClassName: QAClassName
};

const styledOptions = {
  name: 'AxToggle',
  shouldForwardProp: shouldForwardPropExcludeQaClassName((prop: string) => {
    return prop !== 'disabledBranding';
  })
};

const StyledAxToggle = styled(MuiSwitch, styledOptions)<AxToggleProps>(({
  theme,
  disabledBranding
}) => {
  const transform = theme.direction === 'rtl'
    ? `calc(-${ theme.uiKit.switchWidth } + ${ theme.uiKit.switchSizeInner } + (2 * ${ theme.uiKit.switchSpacingInner }))`
    : `calc(${ theme.uiKit.switchWidth } - ${ theme.uiKit.switchSizeInner } - (2 * ${ theme.uiKit.switchSpacingInner }))`;

  return {
    width: 'auto',
    height: 'auto',
    overflow: 'unset',
    padding: 0,

    // default - enabled and off
    '.MuiSwitch-track': {
      borderRadius: theme.uiKit.spacingM,
      height: theme.uiKit.elementSize,
      width: theme.uiKit.switchWidth,
      backgroundColor: theme.uiKit.switchUncheckedColor,
      opacity: theme.uiKit.switchEnabledOpacity
    },

    '.MuiSwitch-switchBase': {
      justifyContent: 'flex-start',
      padding: 0,
      margin: theme.uiKit.switchSpacingInner,
      top: 'auto',
      left: 'auto',

      '& .MuiSwitch-input': {
        margin: 0,
        padding: 0
      },

      // focus styling
      '&.Mui-focusVisible': {
        '& + .MuiSwitch-track': theme.mixins.componentRingFocusStyles()
      },

      // need to check sibling components for toggle checked state
      '&.Mui-checked': {
        transform: `translateX(${ transform })`,

        // enabled and on
        '& + .MuiSwitch-track': {
          backgroundColor: disabledBranding ? theme.uiKit.switchColor : theme.uiKit.switchColorBranded,
          opacity: theme.uiKit.switchEnabledOpacity
        }
      },
      // disabled and off
      '&.Mui-disabled': {
        '& + .MuiSwitch-track': {
          backgroundColor: theme.uiKit.switchDisabledBorderColor,
          opacity: theme.uiKit.switchDisabledOpacity,
          cursor: 'not-allowed'
        }
      }
    },

    '& .MuiSwitch-thumb': {
      height: theme.uiKit.spacingM,
      width: theme.uiKit.spacingM,
      boxShadow: 'none',
      color: theme.uiKit.switchThumbColor
    }
  };
});

/**
 * AxToggle is a styled MUI Switch component.
 *
 * **💡 Note**: This component uses brand colors
 *
  * **💡 Note 2**: Use [AxFormControlLabel](https://storybook.axonify.com/qa13/?path=/docs/mui-components-inputs-axform-axformcontrollabel--docs) for label placement

* ### Links
 * - [Figma](https://www.figma.com/design/mAGiAo8UwCUfdpW9SNdswW/%F0%9F%A7%B0-UIKit?node-id=13328-1249&m=dev)
 * - [MUI Switch API](https://mui.com/material-ui/react-switch/)

 * @example
 * ```tsx
 * <AxFormControlLabel
 *    labelPlacement='start'
 *    label='AxToggle Label'
 *    control={
 *      <AxToggle { ...args } />
 *    }
 * />
 * ```
 */
export const AxToggle = forwardRef(({
  className,
  qaClassName,
  ...props
}: AxToggleProps, ref: Ref<HTMLButtonElement>) => {
  const ToggleProps: AxToggleProps = {
    className: joinClassNames(className, qaClassName),
    qaClassName,
    ...props
  };

  return (
    <StyledAxToggle { ...ToggleProps } ref={ ref }/>
  );
});

export default AxToggle;
