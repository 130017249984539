const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const dateHelpers = require('@common/libs/dateHelpers');

const {
  EVENT_OBJECTIVE,
  CERTIFICATION_OBJECTIVE,
  EVALUATION_OBJECTIVE
} = require('@common/data/models/objectives/GuidedLearningObjectiveType');
const {
  ScheduledLearningEventsAvailable,
  NoScheduledLearningEventsAvailable,
  ManagerEnrollmentRequired,
  Enrolled,
  OnWaitlist,
  Attended,
  AttendanceNotTaken
} = require('@common/data/enums/EventObjectiveEnrollmentType');
const EvaluationObjectiveStatus = require('@common/data/enums/EvaluationObjectiveStatus');
const DAYS_DUE_WARNING_THRESHOLD = 5;

class ObjectiveCardMetaDataView extends Marionette.ItemView {
  getTemplate() {
    return `
    <div class="learning-objective-card__meta-data--content">
      <% if (!isLocked && certStatusText) { %>
        <span class="<%- certStatusCssClass %>"><%- certStatusText %></span>
      <% } %>

      <% if (!isLocked && eventStatusText) { %>
        <span><%- eventStatusText %></span>
      <% } %>

      <% if (!isLocked && evaluationStatusText) { %>
        <span><%- evaluationStatusText %></span>

        <% if (isImported) { %>
          <span><%- t('GuidedLearning.evaluation.imported') %></span>
        <% } %>
      <% } %>

      <% if (!completionDateText && numberOfModulesText) { %>
        <span><%- numberOfModulesText %></span>
      <% } %>

      <% if (!completionDateText && numberOfVideosText) { %>
        <span><%- numberOfVideosText %></span>
      <% } %>

      <% if (!completionDateText && numberOfQuestionsText) { %>
        <span><%- numberOfQuestionsText %></span>
      <% } %>

      <% if (!isLocked && !completionDateText && !evaluationStatusText && rewardPointsText) { %>
        <span><%- rewardPointsText %></span>
      <% } %>

      <% if (!eventStatusText && !evaluationStatusText && isCompletedFastTrack && fastTrackCompletionDateText) { %>
        <span>
          <span class="icon-bolt"></span>
          <%- fastTrackCompletionDateText %>
        </span>
      <% } %>

      <% if (!eventStatusText && !evaluationStatusText && !isCompletedFastTrack && completionDateText) { %>
        <span><%- completionDateText %></span>
      <% } %>
    </div>

    <% if (isLocked) { %>
      <div class="learning-objective-card__meta-data--locked">
        <span class="icon-lock qa-learning-objective-card__lock" role="img" aria-label="<%- t('GuidedLearning.icons.locked') %>"></span>
      </div>
    <% } %>
    `;
  }

  className() {
    return 'learning-objective-card__meta-data';
  }

  templateHelpers() {
    const guidedLearningObjectiveType = this.getOption('guidedLearningObjectiveType');
    const numberOfQuestions = this.getOption('numberOfQuestions');
    const rewardPoints = this.getOption('rewardPoints');
    const numberOfVideos = this.getOption('numberOfVideos');
    const numberOfModules = this.getOption('numberOfModules');
    const completionDate = this.getOption('completionDate');
    const scheduledEventStartDate = this.getOption('scheduledEventStartDate');
    const eventMetaData = this.getOption('eventMetaData');
    const isLocked = this.getOption('isLocked');
    const isOverdue = this.getOption('isOverdue');
    const daysUntilDue = this.getOption('daysUntilDue');
    const isComplete = this.getOption('isComplete');
    const evaluationStatus = this.getOption('evaluationStatus');
    const lastAttemptDate = this.getOption('lastAttemptDate');
    const isImported = this.getOption('isImported');
    const isCompletedFastTrack = this.getOption('isCompletedFastTrack');

    return {
      numberOfQuestionsText: ObjectiveCardMetaDataView._getNumberOfQuestionsText(numberOfQuestions),
      numberOfVideosText: ObjectiveCardMetaDataView._getNumberOfVideosText(numberOfVideos),
      numberOfModulesText: ObjectiveCardMetaDataView._getNumberOfModulesText(numberOfModules),
      rewardPointsText: ObjectiveCardMetaDataView._getRewardPointsText(rewardPoints),
      completionDateText: ObjectiveCardMetaDataView._getCompletionDateText(completionDate, false),
      fastTrackCompletionDateText: ObjectiveCardMetaDataView._getCompletionDateText(completionDate, true),
      eventStatusText: ObjectiveCardMetaDataView._getEventStatusText(
        guidedLearningObjectiveType, eventMetaData, completionDate, scheduledEventStartDate
      ),
      certStatusText: ObjectiveCardMetaDataView._getCertificationStatusMessage({
        guidedLearningObjectiveType,
        isOverdue,
        daysUntilDue,
        isComplete
      }),
      certStatusCssClass: ObjectiveCardMetaDataView._getCertificationStatusCssClass({
        guidedLearningObjectiveType,
        isComplete,
        daysUntilDue
      }),
      evaluationStatusText: ObjectiveCardMetaDataView._getEvaluationStatusMessage(
        guidedLearningObjectiveType, evaluationStatus, lastAttemptDate
      ),
      isLocked,
      isImported,
      isCompletedFastTrack
    };
  }

  static _getRewardPointsText(rewardPoints) {
    let rewardPointsText = null;

    if (rewardPoints === 1) {
      rewardPointsText = I18n.t('assessments.item.rewardPoints.singular');
    } else if (rewardPoints > 1) {
      rewardPointsText = I18n.t('assessments.item.rewardPoints.possible', { points: rewardPoints });
    }

    return rewardPointsText;
  }

  static _getNumberOfQuestionsText(numberOfQuestions) {
    let numberOfQuestionsText = null;

    if (numberOfQuestions === 1) {
      numberOfQuestionsText = I18n.t('assessments.progress.segment.questions.singular');
    } else if (numberOfQuestions > 1) {
      numberOfQuestionsText = I18n.t('assessments.progress.segment.questions.plural', {count: numberOfQuestions});
    }

    return numberOfQuestionsText;
  }

  static _getNumberOfVideosText(numberOfVideos) {
    let numberOfVideosText = null;

    if (numberOfVideos === 1) {
      numberOfVideosText = I18n.t('assessments.progress.segment.video.singular');
    } else if (numberOfVideos > 1) {
      numberOfVideosText = I18n.t('assessments.progress.segment.video.plural', {count: numberOfVideos});
    }

    return numberOfVideosText;
  }

  static _getNumberOfModulesText(numberOfModules) {
    let numberOfModulesText = null;

    if (numberOfModules === 1) {
      numberOfModulesText = I18n.t('assessments.item.content.training.scorm');
    } else if (numberOfModules > 1) {
      numberOfModulesText = I18n.t('assessments.progress.segment.module.plural', {count: numberOfModules});
    }

    return numberOfModulesText;
  }

  static _getCompletionDateText(completionDate, isFastTrack = false) {
    if (!completionDate) {
      return undefined;
    }

    const longFormattedString = dateHelpers.convertDateFormatLongDateWithStrings(completionDate);
    const stringKey = isFastTrack ? 'GuidedLearning.fastTrackCompletionDate' : 'GuidedLearning.completionDate';
    return I18n.t(stringKey, {completed: longFormattedString});
  }

  static _getEventStatusText(guidedLearningObjectiveType, eventMetaData, completionDate, scheduledEventStartDate) {
    if (guidedLearningObjectiveType !== EVENT_OBJECTIVE) {
      return undefined;
    }

    const {
      type,
      numScheduledEventsAvailable
    } = eventMetaData;

    const longFormattedCompletinoDateString = completionDate
      ? dateHelpers.convertDateFormatLongDateWithStrings(completionDate) : '';

    const longFormattedScheduledEventStartDate = scheduledEventStartDate
      ? dateHelpers.convertDateFormatLongDateWithStrings(scheduledEventStartDate) : '';

    let statusText;

    switch (type) {
      case ScheduledLearningEventsAvailable:
        if (numScheduledEventsAvailable === 1) {
          statusText = I18n.t('assessments.item.event.scheduledEvents.singular');
        } else {
          statusText = I18n.t('assessments.item.event.scheduledEvents.plural', {
            count: numScheduledEventsAvailable
          });
        }
        break;

      case NoScheduledLearningEventsAvailable:
        statusText = I18n.t('assessments.item.event.noScheduledEvents');
        break;

      case ManagerEnrollmentRequired:
        statusText = I18n.t('assessments.item.event.managerEnrollmentRequired');
        break;

      case Enrolled:
        statusText = I18n.t('assessments.item.event.enrolledWithDate', {date: longFormattedScheduledEventStartDate});
        break;

      case OnWaitlist:
        statusText = I18n.t('assessments.item.event.onWaitList');
        break;

      case Attended:
        statusText = I18n.t('assessments.item.event.attended', {date: longFormattedCompletinoDateString});
        break;

      case AttendanceNotTaken:
        statusText = I18n.t('assessments.item.event.attendanceNotTaken');
        break;

      default:
        statusText = undefined;
    }

    return statusText;
  }

  static _getCertificationStatusMessage({
    guidedLearningObjectiveType,
    isOverdue,
    daysUntilDue,
    isComplete
  }) {
    if (guidedLearningObjectiveType !== CERTIFICATION_OBJECTIVE) {
      return undefined;
    }

    let message;

    if (isComplete) {
      message = '';
    } else if (isOverdue) {
      message = I18n.t('GuidedLearning.statusHeader.overdue');
    } else if (daysUntilDue === 1) {
      message = I18n.t('GuidedLearning.statusHeader.daysAwayHeaderSingular');
    } else if (daysUntilDue === 0) {
      message = I18n.t('GuidedLearning.statusHeader.dueToday');
    } else if (daysUntilDue) {
      message = I18n.t('GuidedLearning.statusHeader.daysAwayHeader', { daysRemaining: daysUntilDue });
    }

    return message;
  }

  static _getCertificationStatusCssClass({
    guidedLearningObjectiveType,
    isComplete,
    daysUntilDue
  }) {
    return (guidedLearningObjectiveType === CERTIFICATION_OBJECTIVE && daysUntilDue <= DAYS_DUE_WARNING_THRESHOLD && !isComplete)
      ? 'learning-objective-card__cert-status--warning' : '';
  }

  static _getEvaluationStatusMessage(guidedLearningObjectiveType, evaluationStatus, lastAttemptDate) {
    if (guidedLearningObjectiveType !== EVALUATION_OBJECTIVE || !evaluationStatus || evaluationStatus === EvaluationObjectiveStatus.NOT_STARTED) {
      return undefined;
    }

    const isPassed = evaluationStatus === EvaluationObjectiveStatus.PASSED;
    const isFailed = evaluationStatus === EvaluationObjectiveStatus.FAILED;
    const isPending = evaluationStatus === EvaluationObjectiveStatus.PENDING || evaluationStatus === EvaluationObjectiveStatus.PENDING_AFTER_RETRY;

    if (lastAttemptDate) {
      const longFormattedString = dateHelpers.convertDateFormatLongDateWithStrings(lastAttemptDate);
      if (isPassed) {
        return I18n.t('GuidedLearning.evaluation.passedDate', {attemptDate: longFormattedString});
      } else if (isFailed) {
        return I18n.t('GuidedLearning.evaluation.notPassedDate', {attemptDate: longFormattedString});
      }
    } else if (isPending) {
      return I18n.t('GuidedLearning.evaluation.pending');
    }
    return undefined;
  }
}

module.exports = ObjectiveCardMetaDataView;
