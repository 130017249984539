const { isFunction } = require('underscore');
const Backbone = require('Backbone');

const ObjectiveActionItemType = require('@common/data/models/objectives/ObjectiveActionItemType');
const {
  COMPLETE,
  INCOMPLETE,
  NONE
} = require('@common/data/models/objectives/ObjectiveConnectorStatus');

const TYPE_FIELD_NAME = 'type';
const DEPENDS_FIELD_NAME = 'prerequisiteId';
const POINTS_AVAILABLE_FOR_COMPLETION = 'pointsAvailable';
const ACTIONABLE_TOPIC_LEVEL_ITEM_FIELD_NAME = 'actionItem';
const COMPLETED_OBJETIVE_FIELD_NAME = 'complete';
const COMPLETION_DATE_NAME = 'completionDate';
const DETAILS_FIELD_NAME = 'objectiveDetails';
const LOCKED_FIELD_NAME = 'locked';
const CATEGORY_FIELD_NAME = 'category';
const SUBJECT_FIELD_NAME = 'subject';
const TOPIC_FIELD_NAME = 'topic';
const LEVEL_FIELD_NAME = 'level';
const PREVIOUS_CONNECTOR_STATUS_FIELD_NAME = 'previousConnectionStatus';
const NEXT_CONNECTOR_STATUS_FIELD_NAME = 'nextConnectorStatus';
const THUMBNAIL_IMAGE = 'thumbnailImage';
const ENROLLED = 'enrolled';
const IS_COMPLETED_FAST_TRACK = 'isCompletedFastTrack';

// This is an abstract interface, there is no need to create this directly as it will not be usable
class GuidedLearningObjective extends Backbone.Model {

  isComplete() {
    return this.get(COMPLETED_OBJETIVE_FIELD_NAME) === true;
  }

  getName() {
    throw new Error('You need to specify an implementation for getTaskName that specifies the friendly name for this flow task.');
  }

  canBegin() {
    throw new Error('You need to implement this function to get started.');
  }

  isLocked() {
    return this.get(LOCKED_FIELD_NAME) === true;
  }

  canDrillDown() {
    return !this.isLocked();
  }

  getType() {
    return this.get(TYPE_FIELD_NAME);
  }

  getPointsAvailableForCompletion() {
    return this.get(POINTS_AVAILABLE_FOR_COMPLETION);
  }

  getActionableItem() {
    return this.get(ACTIONABLE_TOPIC_LEVEL_ITEM_FIELD_NAME);
  }

  hasDependencies() {
    return this.getPrerequisite() != null;
  }

  getPrerequisite() {
    return this.get(DEPENDS_FIELD_NAME);
  }

  getCompletionDate() {
    return this.get(COMPLETION_DATE_NAME);
  }

  getObjectiveDetails() {
    return this.get(DETAILS_FIELD_NAME);
  }

  getThumbnailImage() {
    return this.get(THUMBNAIL_IMAGE);
  }

  getEnrollmentStatus() {
    const enrollmentStatus = this.get(ENROLLED);

    // If enrollmentStatus is undefined (because it's a Guided Learning program)
    // then we want the status to behave as if it were enrolled.
    if (enrollmentStatus === undefined) {
      return true;
    }

    return enrollmentStatus;
  }

  isStartable() {
    const actionableItem = this.getActionableItem();
    if (actionableItem) {
      return actionableItem.actionType === ObjectiveActionItemType.Startable;
    }

    return false;
  }

  hasStarted() {
    return false;
  }

  isResumable() {
    const inProgressAssessmentGetter = this.get('inProgressAssessmentGetter');

    if (isFunction(inProgressAssessmentGetter)) {
      return inProgressAssessmentGetter() != null;
    }

    return false;
  }

  isResumeFastTrack() {
    const inProgressAssessmentGetter = this.get('inProgressAssessmentGetter');

    if (isFunction(inProgressAssessmentGetter)) {
      const assessment = inProgressAssessmentGetter();
      return assessment.get('fastTrack') || false
    }

    return false;
  }

  isRetake() {
    return this.isStartable() && this.hasStarted();
  }

  getNumberOfQuestions() {
    return 0;
  }

  getNumberOfVideos() {
    return 0;
  }

  getNumberOfModules() {
    return 0;
  }

  getCategoryName() {
    const details = this.getObjectiveDetails();
    return details[CATEGORY_FIELD_NAME];
  }

  getSubjectName() {
    const details = this.getObjectiveDetails();
    return details[SUBJECT_FIELD_NAME];
  }

  getTopicName() {
    const details = this.getObjectiveDetails();
    return details[TOPIC_FIELD_NAME];
  }

  getLevel() {
    return (this.getActionableItem() || {})[LEVEL_FIELD_NAME] || 1;
  }

  getPreviousVisualConnectorStatus() {
    return this.get(PREVIOUS_CONNECTOR_STATUS_FIELD_NAME) || NONE;
  }

  setPreviousVisualConnectorStatus(newStatus) {
    if ([COMPLETE, INCOMPLETE, NONE].includes(newStatus)) {
      this.set(PREVIOUS_CONNECTOR_STATUS_FIELD_NAME, newStatus);
    }
  }

  getNextVisualConnectorStatus() {
    return this.get(NEXT_CONNECTOR_STATUS_FIELD_NAME) || NONE;
  }

  setNextVisualConnectorStatus(newStatus) {
    if ([COMPLETE, INCOMPLETE, NONE].includes(newStatus)) {
      this.set(NEXT_CONNECTOR_STATUS_FIELD_NAME, newStatus);
    }
  }

  isReassignable() {
    return this.getObjectiveDetails().reassignable;
  }

  isCompletedFastTrack() {
    return this.get(IS_COMPLETED_FAST_TRACK) || false;
  }
}

module.exports = GuidedLearningObjective;
