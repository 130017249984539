import { getCssValue } from '@common/modules/react/themes/UiKit';

export default function uiKitSwitch(cssVars: CSSStyleDeclaration) {
  return {
    switchColor: getCssValue('--axToggle-color-default', cssVars),
    switchColorBranded: getCssValue('--axToggle-color-branded', cssVars),
    switchRadioInner: getCssValue('--axToggle-switch-radio-inner', cssVars),
    elementSize: getCssValue('--axElement-size', cssVars),
    switchSizeInner: getCssValue('--axToggle-size-switch-inner', cssVars),
    switchSpacing: getCssValue('--axToggle-switch-base-padding', cssVars),
    switchSpacingInner: getCssValue('--axToggle-spacing-switch-inner', cssVars),
    switchThumbColor: getCssValue('--axToggle-color-thumb', cssVars),
    switchBasePadding: getCssValue('--axToggle-switch-base-padding', cssVars),
    switchTransitionSpeed: getCssValue('--axToggle-transition-speed', cssVars),
    switchUncheckedColor: getCssValue('--axToggle-color-unchecked', cssVars),
    switchWidth: getCssValue('--axToggle-width-switch', cssVars),
    switchWidthBorder: getCssValue('--axToggle-width-border', cssVars),

    switchDisabledBorderColor: getCssValue('--axToggle-color-disabled-border', cssVars),
    switchDisabledCheckedColor: getCssValue('--axToggle-color-disabled-checked', cssVars),
    switchDisabledColor: getCssValue('--axToggle-color-disabled', cssVars),
    switchDisabledOpacity: getCssValue('--axToggle-opacity-disabled', cssVars),

    switchEnabledOpacity: getCssValue('--axToggle-opacity-enabled', cssVars)
  };
}
