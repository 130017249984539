import {
  Badge as MuiBadge,
  styled,
  type BadgeTypeMap as MuiBadgeTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxBadgeCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

declare module '@mui/material/Badge' {
  interface BadgePropsColorOverrides {
    // MUI default
    'primary': true
    'secondary': false
    'info': false
    'error': false
    'success': false
    'warning': true
    // Axonify custom
    'positive': true
    'negative': true
    'informational': true,
  }
}

export type AxBadgeTypeMap<D extends React.ElementType = 'span', P = object> = MuiBadgeTypeMap<D, P & AxBadgeCustomProps>;

export type AxBadgeProps<
  D extends ElementType = AxBadgeTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxBadgeTypeMap<D, P & AxBadgeCustomProps>, D>;

const styledOptions = {
  name: 'AxBadge'
};

const StyledAxBadge = styled(MuiBadge, styledOptions)<AxBadgeProps>(({theme}) => {
  return {
    '.MuiBadge-dot': {
      width: theme.uiKit.badge.size,
      height: theme.uiKit.badge.size,
      borderRadius: theme.uiKit.badge.borderRadius
    },
    '.MuiBadge-colorNegative': {
      // creates consistency with other parts of the app that use a badge to indicate unread items
      backgroundColor: theme.uiKit.badge.unread
    }
  };
});

/**
 * Wrapper for MUI Badge component
 *
 * ### Links
 * - [Badge | MUI](https://mui.com/material-ui/react-badge/)
 */
export const AxBadge: MuiOverridableComponent<AxBadgeTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxBadgeProps, ref: Ref<HTMLSpanElement>) => {
  const BadgeProps: AxBadgeProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxBadge { ...BadgeProps } ref={ ref }>
      { children }
    </StyledAxBadge>
  );
});

export default AxBadge;
